body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiPaper-root.MuiCard-root{
  overflow:auto;
  width: calc(100vw - 300px);
  height: auto;
  max-height: 80vh;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}

.MuiDropzoneArea-root {
  border: none !important;
  background-color: #f7f7f7 !important;
  width: 320px !important;
  min-height: 160px !important;
  margin-top: 18px;
  margin-bottom: 18px;
}
.MuiDropzoneArea-root .MuiTypography-h5{
  margin-top: 30%;
  font-size: 1rem;
}
.MuiDropzonePreviewList-root{
  display: none !important;
}
.MuiDropzoneArea-textContainer p{
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
}
.MuiDropzoneArea-textContainer svg{
  position: absolute;
  bottom: calc(15% + 50px);
  left: calc(50% - 12px);
}
.MuiDialogTitle-root{
  background-color: #f7f7f7;
  height: 48px;
}

.identity-table{
  width: 100%;
  height: auto;
  display: flex;
  font-size: 0.9em;
}

.identity-table .identity-table-record{
  width: 315px;
  margin-right: 20px;
  max-height: 65vh;
  overflow: auto;
}
.identity-table .identity-table-list{
  width: calc(100% - 335px);
  overflow: auto;
  display: flex;
  max-height: 65vh;
}
.identity-table .identity-item{
  height: 100%;
}
.identity-table .identity-table-record .identity-item .identity-item-detail{
  background: #ebf8ff;
}
.identity-table .identity-table-list .identity-item{
  margin-right: 20px;
}
.identity-table .identity-table-list .identity-item .identity-item-detail{
  background: #f6f7fb;
}
.identity-table .identity-item .identity-item-detail{
  padding: 15px 10px;
  width: 295px;
  min-height: calc(100% - 65px);
}
.identity-table .identity-table-list .identity-item .identity-item-detail{
  min-height: calc(100% - 75px);
}
.identity-table .identity-item .identity-item-title{
  font-weight: bold;
  padding-bottom: 15px;
  height: 20px;

}
.identity-table .identity-item .identity-item-title span{
  color: #52799e;
  font-style: italic;
}

.identity-table .identity-item .identity-item-detail tr td{
  max-width: 150px;
}

.identity-table .identity-item .identity-item-detail tr td.email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.identity-table .identity-item .identity-item-detail .odd{
  font-weight: bold;
}
.identity-table .identity-item .identity-item-detail .even{

}
.IM-loading{
  position: absolute;
  width: 400px;
  height: 200px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
}